import {
  loginadmin
} from '@/api/login.js'

export function handleResponse(response) {
  return response.text().then(text => {
    if ([401, 403].indexOf(response.status) !== -1) {
      loginadmin.logout()
      window.location.href = '/login'
    }

    return text && JSON.parse(text)
  })
}
export function handleResponseText(response) {
  if ([401, 403].indexOf(response.status) !== -1) {
    loginadmin.logout()
    window.location.href = '/login'
  }
  return response.text().then(text => {
    if (text !== 'true') {
      return {
        error: text
      }
    } else {
      return JSON?.parse(text)
    }
  })
}
export function handleResponseLink(response) {
  if ([401, 403].indexOf(response.status) !== -1) {
    loginadmin.logout()
    window.location.href = '/login'
  }
  return response.text().then(text => text)
}

export function handleResponseBinary(response, name) {
  if ([401, 403].indexOf(response.status) !== -1) {
    loginadmin.logout()
    window.location.href = '/login'
  }
  return handleBinaryDownload(response, name)
}
function handleBinaryDownload(blobData, name) {

  // Handle the downloaded binary data
  const url = URL.createObjectURL(blobData);
  const a = document.createElement('a');
  a.href = url;
  a.download = name + '.xls'; // Provide a filename for the download (e.g., file.bin)
  document.body.appendChild(a);
  a.click();

  // Clean up
  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
