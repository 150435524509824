import {
    ROOT,
    NOTIFICATION_REGISTER,
    NOTIFICATION_UNREGISTER,
    GET_NOTIFICATION_LIST,
    NOTIFICATION_READ_ONE,
    NOTIFICATION_READ_ALL
} from './constant'
import {
    handleResponse
} from './handle-response'
import {
    requestOptions
} from './request-options'

function getNotiList(userId, pageIndex, pageSize, announcementCategory) {
    return fetch(ROOT + GET_NOTIFICATION_LIST + `userId=${userId}&pageIndex=${pageIndex}&pageSize=${pageSize}&announcementCategory=${announcementCategory}`, requestOptions.get())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function registerNotification(data) {
    return fetch(ROOT + NOTIFICATION_REGISTER, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function readOneNotification(data) {
    return fetch(ROOT + NOTIFICATION_READ_ONE, requestOptions.put(data))
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function readAllNotification(data) {
    return fetch(ROOT + NOTIFICATION_READ_ALL, requestOptions.put(data))
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function unRegisterNotification(username, firebaseRegistrationToken) {
    return fetch(ROOT + NOTIFICATION_UNREGISTER + `username=${username}&firebaseRegistrationToken=${firebaseRegistrationToken}`, requestOptions.delete())
        .then(handleResponse)
        .then(data => {
            return data
        })
}

export const notification = {
    registerNotification,
    unRegisterNotification,
    getNotiList,
    readOneNotification,
    readAllNotification
}