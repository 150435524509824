import {
  ROOT,
  ADMIN_LOGIN,
  GET_FUNCTION_OF_ROLE,
  FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
} from "@/api/constant.js";
import {
  handleResponse
} from "@/api/handle-response";
import {
  requestOptions
} from "@/api/request-options";
import {
  notification
} from "@/api/notification";
import {
  BehaviorSubject
} from "rxjs";
// import store from '../store/index';

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

function login(data) {
  return fetch(ROOT + ADMIN_LOGIN, requestOptions.postBody(data))
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function functionOfRole(data) {
  return fetch(ROOT + GET_FUNCTION_OF_ROLE + data, requestOptions.get())
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function forgotPassword(data) {
  return fetch(
      ROOT + FORGOT_PASSWORD + "?userName=" + data,
      requestOptions.putNoBody()
    )
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function resetPassword(token, password) {
  return fetch(
      ROOT + USER_RESET_PASSWORD + "?token=" + token + "&password=" + password,
      requestOptions.putNoBody()
    )
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

// function logout() {
//   // remove user from local storage to log user out
//   localStorage.clear();
//   currentUserSubject.next(null);
// }
async function logout() {
  const currentUsername = JSON.parse(localStorage.getItem("username"));
  const FCMToken = JSON.parse(localStorage.getItem("FCMToken"));
  if (!currentUsername || !FCMToken) {
    // Handle the case where the values are not set in local storage
    console.log("Username or FCMToken is missing in local storage.");
    return;
  }

  // Call the unregistration API with valid values
  await notification.unRegisterNotification(currentUsername, FCMToken)
    .then(res => {
      if (res.isSuccess) {
        currentUserSubject.next(null);
      }
    })
    .catch(error => {
      // Handle any error from the unregistration request
      console.error("Error during unregistration:", error);
    });
}

export const loginadmin = {
  login,
  functionOfRole,
  logout,
  forgotPassword,
  resetPassword,
};